<template>
  <div v-if="notDestroyed">
    <div class="paydone-container">
      <div div class="paydone-view">
        <div class="paydone-form">
          <div class="paydone-header">
            <div class="paydone-logo"></div>
            <label for="card-element" class="paydone-label">
              {{ text || "Carte " }}
            </label>
          </div>
          <div class="paydone-inputs">
            <div class="paydone-inputs-row">
              <input
                type="text"
                id="name"
                :placeholder="cardholderplaceholder"
                autocomplete="cardholder"
                class="paydone-input"
                v-model="cardholdername"
              />
            </div>
            <div class="paydone-inputs-row">
              <div class="paydone-card-element" id="card-element"></div>
            </div>
          </div>
          <div class="paydone-error" id="card-errors" role="alert"></div>
        </div>

        <button
          id="paydone-submit"
          class="paydone-button"
          :disabled="isLoading"
          v-bind:style="{ backgroundColor: btnBackgroundColor }"
          @click="pay"
          ref="buttonPay"
        >
          <div v-if="isLoading">
            <div class="lds-dual-ring"></div>
          </div>
          <span v-if="!isLoading" id="paydone-button-text">{{
            btnText + " " + this.displayAmount + currencySymb + " "
          }}</span>
        </button>
      </div>
      <div class="paydone-payment-summary hidden paydone-completed-view">
        <h1><span class="paydone-order-status"></span></h1>
      </div>
      <div class="paydone-section hidden paydone-completed-view">
        <div class="paydone-callout">
          <pre></pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";

export default {
  props: {
    text: {
      Type: String,
      default: "Carte bancaire",
    },
    btnText: {
      Type: String,
      default: "Payer",
    },
    cardholderplaceholder: {
      Type: String,
      default: "Nom porteur carte",
    },
    backgroundImage: [String, Object],
    btnBackgroundColor: {
      Type: String,
      default: "#014b73",
    },
    pkKey: {
      Type: String,
      default:
        "pk_test_51Hd0HbIVR4vTMd9iEiGSFvFihmUh4rt5Gg10mBnVZ0naxYCzCFjqHkzo0jBBNnRN3N2xDiY8n2gDZKzAKpvApctD00zF0oOLWf",
    },
    order: {
      Type: Object,
    },
    currencySymb: {
      Type: String,
      default: "€",
    },
    options: {
      Type: Object,
      default: function () {
        return {
          hidePostalCode: true,
          style: {
            base: {
              color: "#32325d",
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSmoothing: "antialiased",
              fontSize: "16px",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "#fa755a",
              iconColor: "#fa755a",
            },
          },
          advancedFraudSignals: false,
          locale: "fr",
        };
      },
    },
    apiEndpoint: {
      Type: String,
      default: "https://api.paydone.fr",
    },
    apiVersion: {
      Type: String,
      default: "v1",
    },
  },
  data: () => ({
    isLoading: true,
    token: null,
    cardholdername: "",
    charge: null,
    clientSecret: "",
    logoPaydone: require("../assets/images/paydone.png"),
    card: null,
    pspKey: "",
    payment: {},
    notDestroyed: true,
  }),
  methods: {
    submit() {
      //this.$refs.elementsRef.submit();

      //adding any handling before calling the payment method
      this.pay();
    },
    tokenCreated(token) {
      this.token = token;
    },
    sendTokenToServer(charge) {
      console.log(charge);
      // Send to charge to your backend server to be processed
      // Documentation here: https://stripe.com/docs/api/charges/create
    },
    async init(pk, mountOn, options) {
      //create order if needed.
      this.isLoading = true;
      this.stripe = await loadStripe(pk, {
        locale: options.locale || this.std_options.locale,
      });
      var elements = this.stripe.elements();
      this.card = elements.create("card", {
        hidePostalCode: true,
        style: options.style || this.std_options.style,
      });
      this.card.mount(mountOn);

      //this.fetchIntent();

      this.card.on("change", function (event) {
        // Disable the Pay button if there are no card details in the Element
        document.querySelector("#paydone-submit").disabled = event.empty;
        // document.querySelector("#card-error").textContent = event.error
        //   ? event.error.message
        //   : "";
      });
      this.isLoading = false;
    },
    handleAction(clientSecret) {
      this.isLoading = true;
      this.stripe.handleCardAction(clientSecret).then((data) => {
        this.isLoading = false;
        if (data.error) {
          this.onError({
            success: false,
            message: "Your card was not authenticated, please try again",
          });
        } else if (data.paymentIntent.status === "requires_confirmation") {
          this.payment.payment = data.paymentIntent;
          this.updateTransaction((err, tx) => {
            if (err) return this.onError(err);
            this.onSuccess({
              success: true,
              paymentId: tx.payment.id,
              transactionId : tx._id,
              orderId : this.payment.orderId,
            });
          });
        }
      });
    },
    onError(err) {
      this.$refs.buttonPay.disabled = true;
      this.$refs.buttonPay.value = "Error!!";
      this.$refs.buttonPay.innerHTML = "Not available";
      this.$emit("paydoneError", err);
      this.notDestroyed = false;
      this.isLoading = false;
    },
    onSuccess(result) {
      //console.log("Success", msg);
      //fire event Success, with success object
      this.$emit("paydoneSuccess", result);
      this.notDestroyed = false;
      this.isLoading = false;
    },
    fetchIntent() {
      //sending intent to paydone server
      // Disable the button until we have Stripe set up on the page
      //document.querySelector("paydone-submit").disabled = true;
      this.isLoading = true;
      fetch(`${this.apiEndpointUrl}/public/pay`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ payment: this.payment }),
      })
        .then(function (result) {
          return result.json();
        })
        .then((data) => {
          if (data.error) {
            console.log("Error while fetchin....");
          } else {
            this.clientSecret = data.clientSecret;
          }
        })
        .catch((e) => {
          console.log("Error occured while connecting paydone API", e);
        });
    },
    pay() {
      //changeLoadingState(true)
      this.isLoading = true;
      var data = {
        billing_details: {
          email: this.payment.customerId,
          //address : this.order.shipping.address
          /**
          billing_details attr : email, address, name, phone
           */
        },
      };
      if (this.cardholdername) {
        data["billing_details"]["name"] = this.cardholdername;
      }
      // Collect payment details
      this.stripe
        .createPaymentMethod("card", this.card, data)
        .then((result) => {
          if (result.error) {
            this.onError(result.error);
            this.isLoading = false;
          } else {
            this.payment.paymentMethodId = result.paymentMethod.id;

            return fetch(`${this.apiEndpoint}/public/pay`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "x-access-token": this.pkKey,
              },
              body: JSON.stringify({ payment: this.payment }),
            });
          }
        })
        .then((result) => {
          return result.json();
        })
        .then((paymentData) => {
          if (paymentData.data.requiresAction) {
            this.handleAction(paymentData.data.clientSecret);
            this.isLoading = false;
          } else if (paymentData.data.error || !paymentData.success) {
            this.onError({ success: false, error: paymentData.data.error });
            this.isLoading = false;
          } else {
            this.orderComplete(paymentData);
            this.isLoading = false;
          }
        });
    },
    retrievePaymentIntent(cb) {
      this.isLoading = true;
      this.stripe.retrievePaymentIntent(this.clientSecret).then((result) => {
        cb(result);
        this.isLoading = false;
      });
    },
    orderComplete: function (paydata) {
      this.isLoading = true;
      this.stripe
        .retrievePaymentIntent(paydata.data.clientSecret)
        .then((result) => {
          var paymentIntent = result.paymentIntent;
          //var paymentIntentJson = JSON.stringify(paymentIntent, null, 2);
          document.querySelectorAll(".payment-view").forEach(function (view) {
            view.classList.add("hidden");
          });
          document
            .querySelectorAll(".paydone-completed-view")
            .forEach(function (view) {
              view.classList.remove("hidden");
            });
          document.querySelector(".paydone-order-status").textContent =
            paymentIntent.status === "succeeded" ||
            paymentIntent.status === "requires_capture"
              ? "done!"
              : "failed";
          //document.querySelector('pre').textContent = paymentIntentJson
          this.onSuccess(paydata);
          this.isLoading = false;
        });
    },
    loading(yeah) {
      document.getElementById("paydone-submit").disabled = yeah;
    },
    addCustomerToOrder(orderId, customerObj, cb) {
      //add a customer (transactio, products and amount to existing order)
      fetch(`${this.apiEndpointUrl}/public/orders/${orderId}/customers/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ customer: customerObj, token: this.pkKey }),
      })
        .then(function (result) {
          return result.json();
        })
        .then((data) => {
          if (data.error) {
            console.log("Error occured", data.error);
          } else {
            return cb({
              customerId: data.customerId,
              transactionId: data.transactionId,
            });
          }
        })
        .catch((e) => {
          console.log("Error occured while connecting paydone API", e);
        });
    },
    postOrder(order, cb) {
      //add a customer (transactio, products and amount to existing order)
      this.isLoading = true;
      fetch(`${this.apiEndpointUrl}/public/orders/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": this.pkKey,
        },
        body: JSON.stringify({ order: order }),
      })
        .then(function (result) {
          return result.json();
        })
        .then((data) => {
          //console.log('data from fetching postorder', data)
          this.isLoading = false;
          if (!data.success) {
            return cb({ success: false, message: data.message }, null);
          } else {
            //console.log("Creating nex order ", data.orderId )
            return cb(null, {
              order: data.order,
              payment: data.payment,
            });
          }
        })
        .catch((e) => {
          this.isLoading = false;
          console.log("Error occured while connecting paydone API", e);
        });
    },
    orderInit(cb) {
      //create or updating order at the server level
      if (this.order) {
        //creating por updating new order
        //check compononts, amounts, cutsomer... will be done at the server level.
        //on error, rdrop back to onError.
        this.postOrder(this.order, (err, result) => {
          if (err) return cb(err, null);
          this.order = result.order;
          this.payment = result.payment;
          //this.order._id = result.orderId //updating the order orbject with ID and technical info.
          return cb(err, this.order);
        });
      }
    },
    checkPublicKey(cb) {
      this.isLoading = true;
      fetch(`${this.apiEndpointUrl}/public/key/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: this.pkKey }),
      })
        .then(function (result) {
          return result.json();
        })
        .then((data) => {
          this.isLoading = false;
          if (!data.success) {
            return cb("Error", null);
          } else {
            return cb(null, data.payload);
          }
        })
        .catch((e) => {
          this.isLoading = false;
          console.log("Error occured while connecting paydone API", e);
        });
    },
    updateTransaction(cb) {
      //updating existing transaction (when card requires Action 3D secure for instance)
      console.log("updating Transaction", this.payment);
      fetch(`${this.apiEndpointUrl}/public/transactions/${this.payment._id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": this.pkKey,
        },
        body: JSON.stringify({ transaction: this.payment }),
      })
        .then(function (result) {
          return result.json();
        })
        .then((data) => {
          if (!data.success) {
            return cb(
              { success: false, message: "Error while updating transaction" },
              null
            );
          } else {
            console.log("updating transaction", data.transaction._id);
            this.payment = data.transaction;
            return cb(null, data.transaction);
          }
        })
        .catch((e) => {
          console.log("Error occured while connecting paydone API", e);
          this.onError({
            success: false,
            message: "Error while transation update",
          });
        });
    },
  },
  mounted: function () {
    //console.log("Mounting PaydoneElement ...");
    //check the public key
    this.checkPublicKey((err, payload) => {
      if (err)
        return this.onError({ success: false, message: "Invalid public key" });
      this.pspKey = payload.pspKey;
      this.orderInit((err) => {
        if (err) return this.onError(err);
        //console.log(order)
        this.init(this.pspKey, "#card-element", this.options);
      });
    });
  },
  computed: {
    displayAmount() {
      return this.payment ? this.payment.amount / 100 : "0!!";
    },
    apiEndpointUrl() {
      return this.apiEndpoint; //+ '/' + this.apiVersion
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/style.scss";
.paydone-button:disabled {
  cursor: wait;
}
.lds-dual-ring {
  display: inline-block;
  width: 20px;
  height: 20px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  margin: 0px;
  border-radius: 50%;
  border: 6px solid #0e979c;
  border-color: #0e979c transparent #0e979c transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>