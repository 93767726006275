<script>
import PaydoneElement from './PaydoneElement'
import Vue from 'vue'

export default {
      components: {
             PaydoneElement,
        },
    data: () => ({
                loading: false,
                amount: 100,
                publishableKey: '',
                std_options: {
                    hidePostalCode: true,
                style: {
                    base: {
                    color: "#32325d",
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: "antialiased",
                    fontSize: "16px",
                    "::placeholder": {
                        color: "#aab7c4",
                    },
                    },
                    invalid: {
                        color: "#fa755a",
                        iconColor: "#fa755a",
                    },
                },
                advancedFraudSignals: false,
                locale: "fr",
                },
                order: {},
                clientSecret: "",
                callback : null,    
            }),
    method : {
        init (pk,mountOn,order,options,cb){            
            this.order = order;
            this.callback = cb
            options = {...this.std_options, ...options};
            var ComponentClass = Vue.extend(PaydoneElement)
            var paydoneElement = new ComponentClass({
                    propsData: { 
                        text: options.text,
                        cardholderplaceholder : options.cardholderplaceholder,
                        pkKey : pk,
                        options : this.std_options,
                        order : order,
                        apiEndpoint : process.env.VUE_APP_API_URL
                         }
                })
            paydoneElement.$mount(mountOn)
            paydoneElement.$on('paydoneSuccess', (e) => {
                this.callback(e)
                });
            paydoneElement.$on('paydoneError', (e) => {
                this.callback(e)
            });
            console.log("mounted on", mountOn)
            this.callback = cb
        }
    }

}
</script>